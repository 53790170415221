<template>
  <div>
    <div class="top_div">
      <i class="el-icon-arrow-left left_link"></i>心理测评
    </div>
    <div class="chat_list">
      <div class="chat_item">
        <img src="@/assets/ava_default.png" />
        <div class="chat_content" style="line-height: 1.5">
          恭喜您完成心理测评，心理测评报告正在生成中，大 约1-2分钟，请耐心等待。
        </div>
      </div>
      <div class="chat_item">
        <img src="@/assets/ava_default.png" />
        <div class="chat_content">您的心理测评报告已生成，请点击查看。</div>
      </div>
      <div
        class="chat_item"
        style="align-items: flex-start"
        @click="see_report"
      >
        <img src="@/assets/ava_default.png" />
        <div class="see_report">
          <img src="@/assets/create_report_title.png" />
          <div class="see_report_bg">
            <img src="@/assets/create_report.png" />
            <div class="see_report_text">点击查看报告</div>
          </div>
        </div>
      </div>
    </div>
    <div class="redo" @click="redo">重新测评</div>
  </div>
</template>

<script>
export default {
  name: "ReportCreate",
  data() {
    return {
      report_id: "",
    };
  },
  mounted() {
    let that = this;
    //没有uid就进去选择病人
    if (this.$route.query.id) {
      this.report_id = this.$route.query.id;
    } else {
      this.$message.error("参数错误");
      setTimeout(() => {
        that.$router.push({
          path: "choose_patient",
        });
      }, 1000);
    }
  },
  methods: {
    //重做
    async redo() {
      await this.$router.push({
        path: "/choose_patient",
      });
    },
    //查看报告
    async see_report() {
      await this.$router.push({
        path: "report_summary",
        query: { id: this.report_id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 131px;
  font-size: 48px;
  font-weight: bold;
  color: #101010;
  background-color: #fff;
}
.left_link {
  margin-left: 15px;
}
.chat_list {
  padding: 34px;
}
.chat_item {
  display: flex;
  align-items: center;
  margin: 12px 0px;
}
.chat_content {
  width: 624px;
  height: 96px;
  padding-right: 29px;
  padding-left: 45px;
  margin-left: 12px;
  font-size: 30px;
  font-weight: 400;
  line-height: 96px;
  color: #606266;
  background: #ffffff;
  border: 1px solid rgba(221, 221, 221, 0.91);
  border-radius: 53px 48px 48px 0px;
  box-shadow: 0px 0px 0px 0px rgba(184, 184, 184, 0.1);
}
.see_report {
  width: 211px;
  height: 272px;
  padding: 35px;
  margin-left: 12px;
  text-align: center;
  cursor: pointer;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 9px 0px rgba(184, 184, 184, 0);
}
.see_report_bg {
  width: 189px;
  height: 183px;
  padding-top: 40px;
  margin: 0px auto;
  margin-top: 30px;
  background: #f7f7f7;
  border-radius: 10px;
}
.see_report_text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #606266;
  text-align: center;
}
.redo {
  width: 728px;
  height: 102px;
  margin: 0px auto;
  margin-top: 400px;
  font-size: 36px;
  font-weight: 400;
  line-height: 102px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background: #078599;
  border-radius: 51px;
}
</style>
